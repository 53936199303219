import React, { PureComponent } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import AssetDetails from './AssetDetails';
import JobDetails from './JobDetails.tsx';
// import JobDetails from './JobDetails';
// import AssetDetails from './AssetDetails';
import Preview from './Preview';
import FieldReport from './FieldReport';
import FullReport from './FullReport';
import * as TaskConstants from '../Constants/TaskConstants';
import TaskInspect from './TaskInspect';
import AttachAsset from './AttachAsset';
import TaskInspectLLG from './TaskInspectLLG';

export default class TaskDetailsTab extends PureComponent {


    render() {
        let {
            TabIndex,
            SelectedTask
        } = this.props.Task;


        const isAssetDetailsDisabled = !SelectedTask.locationId || SelectedTask.assetType === "LLG" || SelectedTask.taskType === "LLG";
        const isLLGTask = SelectedTask.assetType === "LLG" || SelectedTask.taskType === "LLG";
        const isFullChecklistEnabled = SelectedTask.status === TaskConstants.TASK_STATUS_CLOSED;

        const isAttachAssetTabEnabled = SelectedTask.locationId ? false : true;

        const isInspectEnabled =
            (window.loggedInUserDetails.role === "APPROVER"||window.loggedInUserDetails.role === "FIELD-ENGG")
            &&
            (
                SelectedTask.status === TaskConstants.TASK_STATUS_ONGOING
                || SelectedTask.status === TaskConstants.TASK_STATUS_ONHOLD
                || SelectedTask.status === TaskConstants.TASK_STATUS_AMENDED
                || SelectedTask.status === TaskConstants.TASK_STATUS_OPEN
            ) && !isAttachAssetTabEnabled;

        const isLLGInspectEnabled =
            (window.loggedInUserDetails.role === "APPROVER" || window.loggedInUserDetails.role === "FIELD-ENGG")
            &&
            (
                SelectedTask.status === TaskConstants.TASK_STATUS_OPEN
                || SelectedTask.status === TaskConstants.TASK_STATUS_ONGOING
                || SelectedTask.status === TaskConstants.TASK_STATUS_CANCELLED
                || SelectedTask.status === TaskConstants.TASK_STATUS_ONGOING
                || SelectedTask.status === TaskConstants.TASK_STATUS_AMENDED
                || SelectedTask.status === TaskConstants.TASK_STATUS_ONHOLD
            );

        return (
            <React.Fragment>
                <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeTaskTab({ TabIndex: index })}>
                    <TabList>
                        {/* <Tab disabled>ASSET DETAILS</Tab> */}
                        <Tab>PREVIEW</Tab>
                        <Tab>JOB DETAILS</Tab>
                        <Tab disabled={isAssetDetailsDisabled}>ASSET DETAILS</Tab>
                        <Tab>FIELD REPORT</Tab>
                        {isLLGTask ?
                            <Tab disabled={!isLLGInspectEnabled}>INSPECT</Tab>
                            :
                            <Tab disabled={!isInspectEnabled}>INSPECT</Tab>
                        }
                        <Tab disabled={!isAttachAssetTabEnabled}>ATTACH ASSET</Tab>
                       
                        <Tab disabled={!isFullChecklistEnabled}>FULL CHECKLIST REPORT</Tab>
                    </TabList>

                    <TabPanel>
                        <Preview
                            {...this.props}
                        />
                    </TabPanel>
                    <TabPanel>
                        <JobDetails
                            {...this.props}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AssetDetails
                            {...this.props}
                        />
                    </TabPanel>
                    <TabPanel>
                        <FieldReport
                            {...this.props}
                        />
                    </TabPanel>
                    {isLLGTask ?
                        <TabPanel>
                            <TaskInspectLLG
                                {...this.props}
                            />
                        </TabPanel>
                        :
                        <TabPanel>
                            <TaskInspect
                                {...this.props}
                            />
                        </TabPanel>
                    }
                    <TabPanel>
                        <AttachAsset {...this.props} />
                    </TabPanel>
                    <TabPanel>
                        <FullReport
                            {...this.props}
                        />
                    </TabPanel>

                </Tabs>
            </React.Fragment>
        )
    }
}
