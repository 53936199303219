import * as CONSTANTS from './../Constants/TaskConstants';
import * as JOB_CONSTANTS from './../../Jobs/Constants/JobConstants';
import * as ASSET_CONSTANTS from './../../Assets/Constants/AssetConstants';
import { TaskInitialState } from "../Constants/TaskInitialState";

export class TaskActions {

    resetTaskBreadCrumb = (payload) => {
        return { type: CONSTANTS.RESET_TASK_BREADCRUMB, payload }
    }

    changeTaskTab = (payload) => {
        return { type: CONSTANTS.CHANGE_TASK_TAB, payload }
    }

    changeLLGTaskTab = (payload) => {
        return { type: CONSTANTS.CHANGE_LLG_TASK_TAB, payload }
    }

    resetAssetTab = (payload) => {
        return { type: ASSET_CONSTANTS.RESET_ASSET_TAB, payload }
    }

    cleanTaskDashboard = (payload) => {
        return dispatch => {
            dispatch(this.cleanTaskDashboardAsync(payload));
            dispatch(this.resetTaskBreadCrumb(payload));
            payload.SelectedTask = null;
            payload.ShowTaskDetailsTab = false;
            dispatch(this.showTaskDetailsTab(payload));
        }
    }

    cleanTaskDashboardAsync = (payload) => {
        return { type: CONSTANTS.CLEAN_TASK_DASHBOARD, payload }
    }

    cleanTaskDashboardWithFilter = (payload) => {
        return dispatch => {
            dispatch(this.cleanTaskDashboardWithFilterAsync(payload));
            dispatch(this.resetTaskBreadCrumb(payload));
            payload.SelectedTask = null;
            payload.ShowTaskDetailsTab = false;
            dispatch(this.showTaskDetailsTab(payload));
        }
    }

    cleanTaskDashboardWithFilterAsync = (payload) => {
        return { type: CONSTANTS.CLEAN_TASK_DASHBOARD_WITH_FILTER, payload }
    }

    loadTaskDashboard = (payload) => {
        return { type: CONSTANTS.LOAD_TASK_DASHBOARD, payload }
    }

    openCloseAlertModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_TASK_ALERT_MODAL, payload };
    }

    fixTaskDashboard = (payload) => {
        return { type: CONSTANTS.FIX_TASK_DASHBOARD, payload };
    }

    sortTaskDashboard = (payload) => {
        return { type: CONSTANTS.SORT_TASK_DASHBOARD, payload };
    }

    toggleTaskView = (payload) => {
        return { type: CONSTANTS.TOGGLE_TASK_VIEW, payload };

    }

    loadTasksAsync = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadTaskDashboard(payload));
            payload.apiObject.getTaskDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Tasks = response[0].data;
                payload.TotalTasksCount = response[1].data;
                payload.isOngoingLoading = false;
                dispatch(this.loadTaskDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading tasks";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    showTaskDetailsTab = (payload) => {
        return dispatch => {
            dispatch(this.showTaskDetailsPane(payload));
            dispatch(this.resetAssetTab(payload));
            payload.hideAddEditAssetPanel = true;
            payload.selectedAssetId = payload.SelectedTask?.locationId;
            dispatch(this.showAssetDetailsPane(payload))
        }

    }

    showTaskDetailsPane = (payload) => {
        return { type: CONSTANTS.SHOW_TASK_DETAILS_TAB, payload };

    }

    showAssetDetailsPane = (payload) => {
        return { type: ASSET_CONSTANTS.OPEN_CLOSE_ADD_EDIT_ASSET_PANEL, payload };

    }

    switchLLGTaskView = (payload) => {
        return { type: CONSTANTS.SWITCH_LLG_TASK_VIEW, payload };
    }

    showHideDashboardColumns = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_DASHBOARD_COLUMNS, payload };
    }

    //DASHBOARD FILTER start

    findFilteredTasksAsync = (payload) => {
        localStorage.setItem("config", JSON.stringify(payload.config));
        return dispatch => {
            dispatch(this.changeTaskFilterValue(payload));
            payload.apiObject.getTaskDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Tasks = response[0].data;
                payload.TotalTasksCount = response[1].data;
                dispatch(this.loadTaskDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading tasks";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    changeTaskFilterValue = (payload) => {
        switch (payload.filterType) {
            case "status":
                return { type: CONSTANTS.CHANGE_TASK_STATUS_FILTER_VALUE, payload };
            case "site":
                return { type: CONSTANTS.CHANGE_TASK_SITE_FILTER_VALUE, payload };
            case "client":
                return { type: CONSTANTS.CHANGE_TASK_CLIENT_FILTER_VALUE, payload };
            case "jobNumber":
                return { type: CONSTANTS.CHANGE_TASK_JOB_NUMBER_FILTER_VALUE, payload };
            case "certificateNumber":
                return { type: CONSTANTS.CHANGE_TASK_CERTIFICATE_NUMBER_FILTER_VALUE, payload };
            case "asset":
                return { type: CONSTANTS.CHANGE_TASK_ASSET_FILTER_VALUE, payload };
            case "assetType":
                return { type: CONSTANTS.CHANGE_TASK_ASSET_TYPE_FILTER_VALUE, payload };
            case "quotationNumber":
                return { type: CONSTANTS.CHANGE_TASK_QUOTATION_NUMBER_FILTER_VALUE, payload };

            default:
                break;
        }
    }

    //DASHBOARD FILTER end

    // PREVIEW Tab start

    loadOtherTaskInspectorsAsync = (payload) => {
        return { type: CONSTANTS.LOAD_OTHER_TASK_INSPECTORS, payload };
    }

    generateOrReopenTask = (payload) => {
        return dispatch => {
            payload.isOngoingUpdate = true;
            dispatch(this.generateOrReopenTaskAsync(payload))

            let isTriggerNotification =
                (payload.SelectedTask.status === 8 && payload.ToBeUpdatedStatus === 2) ||
                (payload.SelectedTask.status === 16 && payload.ToBeUpdatedStatus === 32)


            if (payload.ToBeUpdatedStatus === 8) {

                payload.apiObject.approveTaskAPI(payload.SelectedTask.id).then((response) => {
                    payload.isOngoingUpdate = false;
                    payload.SelectedTask = response;
                    dispatch(this.generateOrReopenTaskAsync(payload))

                    payload.hideAlert = false;
                    payload.alertHeader = "Success !!";
                    payload.alertSubText = `Task ${CONSTANTS.TASK_STATUS_UPDATED[payload.ToBeUpdatedStatus]} successfully. ${isTriggerNotification ? "Please check email for notification" : ""} `;
                    dispatch(this.openCloseAlertModal(payload));

                    if (isTriggerNotification) {
                        let changedTasks = [];
                        changedTasks.push(response);
                        payload.apiObject.sendNotification(changedTasks);
                    }

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                        payload.Tasks = [];
                        payload.loadingDashboard = true;
                        payload.offset = 0;
                        payload.limit = TaskInitialState.limit;
                        payload.LoadingTaskDashboard = true;
                        payload.config = JSON.parse(localStorage.getItem("config"));
                        dispatch(this.cleanTaskDashboardWithFilter(payload));
                        dispatch(this.loadTasksAsync(payload));
                    }, 2500);

                }).catch((error) => {
                    console.error("Error: ", error);

                    payload.hideAlert = false;
                    payload.alertHeader = "Error !!";
                    payload.alertSubText = "Error while updating task: " + error;
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2500);

                })
            }
            else {

                payload.apiObject.generateOrReopenTaskAPI(
                    payload.SelectedTask.id,
                    payload.ToBeUpdatedStatus,
                    payload.SelectedTask.revisionComments,
                    (payload.SelectedTaskNewInspector && (payload.SelectedTask.inspectorId !== payload.SelectedTaskNewInspector?.value))
                        ? payload.SelectedTaskNewInspector.value : null)
                    .then((response) => {
                        payload.isOngoingUpdate = false;
                        payload.SelectedTask = response;
                        dispatch(this.generateOrReopenTaskAsync(payload))

                        payload.hideAlert = false;
                        payload.alertHeader = "Success !!";
                        payload.alertSubText = `Task ${CONSTANTS.TASK_STATUS_UPDATED[payload.ToBeUpdatedStatus]} successfully. ${isTriggerNotification ? "Please check email for notification" : ""}`;
                        dispatch(this.openCloseAlertModal(payload));
                        if (isTriggerNotification) {
                            let changedTasks = [];
                            changedTasks.push(response);
                            payload.apiObject.sendNotification(changedTasks);
                        }
                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                            payload.Tasks = [];
                            payload.loadingDashboard = true;
                            payload.offset = 0;
                            payload.limit = TaskInitialState.limit;
                            payload.LoadingTaskDashboard = true;
                            payload.config = JSON.parse(localStorage.getItem("config"));
                            dispatch(this.openCloseAlertModal(payload));
                            dispatch(this.cleanTaskDashboardWithFilter(payload));
                            dispatch(this.loadTasksAsync(payload));
                        }, 2500);

                    }).catch((error) => {
                        console.error("Error: ", error);

                        payload.hideAlert = false;
                        payload.alertHeader = "Error !!";
                        payload.alertSubText = "Error while updating task";
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2500);

                    })
            }
        }
    }

    showTaskPreviewCertificateBrowser = (payload) => {
        return { type: CONSTANTS.SHOW_TASK_PREVIEW_CERTIFICATE_BROWSER, payload };
    }

    loadChildLLGTasks = (payload) => {
        return { type: CONSTANTS.LOAD_CHILD_LLG_TASKS, payload };
    }

    loadChildLLGTasksAsync = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadChildLLGTasks(payload));
            payload.apiObject.getLLGChildTasks(payload.parentObservationId).then((response) => {
                payload.ChildLLGTasks = response.data;
                payload.isOngoingLoading = false;
                dispatch(this.loadChildLLGTasks(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Child LLG tasks";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    loadChildLLGTaskListAsync = (payload) => {
        return { type: CONSTANTS.LOAD_CHILD_LLG_TASK_LIST, payload };
    }

    loadChildLLGTaskList = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadChildLLGTaskListAsync(payload));
            payload.apiObject.getLLGChildTasks(payload.parentObservationId).then((response) => {
                payload.ChildLLGTasks = response.data;
                payload.isOngoingLoading = false;
                dispatch(this.loadChildLLGTaskListAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Child LLG tasks";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


    changeRevisionComments = (payload) => {
        return { type: CONSTANTS.CHANGE_REVISION_COMMENTS, payload };
    }

    generateOrReopenTaskAsync = (payload) => {
        return { type: CONSTANTS.REOPEN_OR_GENERATE_TASK, payload };
    }

    showTaskPdf = (payload) => {
        return { type: CONSTANTS.SHOW_TASK_PDF, payload };
    }

    cleanTaskPdf = (payload) => {
        return { type: CONSTANTS.CLEAN_TASK_PDF, payload };
    }

    loadUnloadTaskPreview = (payload) => {
        return { type: CONSTANTS.LOAD_UNLOAD_TASK_PREVIEW, payload };
    }

    changeSelectedTaskInspector = (payload) => {
        return { type: CONSTANTS.CHANGE_AND_REASSIGN_TASK_INSPECTOR, payload };
    }

    showTaskPdfAsync = (payload) => {
        return dispatch => {

            payload.isOngoingLoading = true;
            dispatch(this.loadOtherTaskInspectorsAsync(payload));
            payload.apiObject.getInspector(payload.SelectedTask.taskTypeId).then((response) => {
                payload.Inspectors = response;
                payload.isOngoingLoading = false;
                payload.PDFURL = "";
                dispatch(this.loadOtherTaskInspectorsAsync(payload));
                dispatch(this.showTaskPdf(payload));
                if (payload.SelectedTask.status !== 16) {
                    payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedTask.id}${payload.TabIndex === 3 ? '&category=2' : ''}`;
                    dispatch(this.showTaskPdf(payload));
                    dispatch(this.loadUnloadTaskPreview(payload));
                }
                else {
                    if (payload.SelectedTask.certificateFile) {
                        payload.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.SelectedTask.id}&id=${JSON.parse(payload.SelectedTask.certificateFile).blobKey}${payload.TabIndex === 3 ? '&category=2' : ''}`;
                    }
                    else {
                        payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedTask.id}${payload.TabIndex === 3 ? '&category=2' : ''}`;
                    }
                    payload.isOngoingLoading = false;
                    dispatch(this.showTaskPdf(payload));
                    dispatch(this.loadUnloadTaskPreview(payload));
                }
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading inpsectors";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })

        }
    }
    showTaskfFullReportAsync = (payload) => {
        return dispatch => {

            payload.isOngoingLoading = true;
            dispatch(this.loadOtherTaskInspectorsAsync(payload));
            payload.apiObject.getInspector(payload.SelectedTask.taskTypeId).then((response) => {
                payload.Inspectors = response;
                payload.isOngoingLoading = false;
                payload.PDFURL = "";
                dispatch(this.loadOtherTaskInspectorsAsync(payload));
                dispatch(this.showTaskPdf(payload));
                if (payload.SelectedTask.status !== 16) {
                    payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedTask.id}${payload.TabIndex === 6 ? '&category=3' : ''}`;
                    dispatch(this.showTaskPdf(payload));
                    dispatch(this.loadUnloadTaskPreview(payload));
                }
                else {
                    if (payload.SelectedTask.certificateFile) {
                        payload.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.SelectedTask.id}&id=${JSON.parse(payload.SelectedTask.certificateFile).blobKey}${payload.TabIndex === 6 ? '&category=3' : ''}`;
                    }
                    else {
                        payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedTask.id}${payload.TabIndex === 6 ? '&category=3' : ''}`;
                    }
                    payload.isOngoingLoading = false;
                    dispatch(this.showTaskPdf(payload));
                    dispatch(this.loadUnloadTaskPreview(payload));
                }
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading inpsectors";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })

        }
    }

    selectChildLLGTask = (payload) => {
        return { type: CONSTANTS.SELECT_CHILD_LLG_TASK, payload };
    }

    changeChildLLGTaskSearchText = (payload) => {
        return { type: CONSTANTS.CHANGE_CHILD_LLG_TASK_SEARCH_TEXT, payload };
    }

    showLLGTaskPdfAsync = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = false;
            // dispatch(this.showTaskPdf(payload))
            payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedChildLLGTask?.id}${payload.TabIndex === 3 ? '&category=2' : payload.TabIndex === 6 ? '&category=3' : ''}`;
           // payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedChildLLGTask?.id}${payload.TabIndex === 3 ? '&category=2' : ''}`;
            dispatch(this.selectChildLLGTask(payload));
            dispatch(this.showTaskPdf(payload));
            dispatch(this.loadUnloadTaskPreview(payload));
        }
    }
    showLLGTaskBookletAsync = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = false;
            // dispatch(this.showTaskPdf(payload))
            payload.PDFURL = `${window.API_URL}${CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.SelectedTask?.id}${payload.TabIndex === 3 ? '&category=2' : ''}`;
            dispatch(this.selectChildLLGTask(payload));
            dispatch(this.showTaskPdf(payload));
            dispatch(this.loadUnloadTaskPreview(payload));
        }
    }


    openCloseTaskConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_TASK_CONFIRM_MODAL, payload };
    }

    // PREVIEW Tab end


    // Job Details Tab Start

    uploadJobFilesFromTaskAsync = (payload) => {
        return { type: CONSTANTS.UPLOAD_JOB_FILES_FROM_TASK, payload }
    }

    uploadJobFiles = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.uploadJobFilesFromTaskAsync(payload));
            payload.apiObject._uploadJobFiles(payload.scheduleId, payload.uploadedFiles).then((response) => {
                payload.isOngoingLoad = false;
                payload.files = response;
                payload.errorText = "Files uploaded successfully"
                dispatch(this.uploadJobFilesFromTaskAsync(payload));
                setTimeout(() => {
                    payload.errorText = ""
                    dispatch(this.uploadJobFilesFromTaskAsync(payload));
                }, 2000);
            }).catch((error) => {
                console.log("Error while uploading job files: ", error);
                payload.errorText = "Error while uploading job files";
                dispatch(this.showHideUploadJobFilesErrorText(payload));

                setTimeout(() => {
                    payload.errorText = "Error while uploading job files";
                    dispatch(this.showHideUploadJobFilesErrorText(payload));
                }, 2000);
            });
        }
    }

    showHideTimeClock = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_TIME_CLOCK, payload };
    }

    onChangeSelectedTaskJobDetailsTab = (payload) => {
        return { type: CONSTANTS.ON_CHANGE_SELECTED_TASK_JOB_DETAILS_TAB, payload };
    }

    onChangeSelectedTaskJobDetailsTabAsync = (payload) => {
        return { type: CONSTANTS.ON_SAVE_SELECTED_TASK_JOB_DETAILS_TAB, payload };
    }

    onSaveSelectedTaskJobDetailsTab = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.onChangeSelectedTaskJobDetailsTabAsync(payload));
            let currentTask = payload.SelectedTask;
            let actualInspectionDateTime = new Date(currentTask.actualInspectionDate);

            let isLLG = currentTask.assetType === "LLG" || currentTask.taskType === "LLG";

            let changedJobSitePayload = {
                "observationDetails": {
                    "id": currentTask.id,
                    "actualInspectionDate": actualInspectionDateTime,
                    "isLLG": currentTask.assetType === "LLG" || currentTask.taskType === "LLG"
                },
                "scheduleDetails": {
                    scheduleId: currentTask.scheduleId,
                    vendorDetails: currentTask.vendorDetails,
                    subVendorDetails: currentTask.subVendorDetails
                },
                "siteDetails": {
                    "id": currentTask.siteId,
                    "street": currentTask.siteStreet,
                    "city": currentTask.siteCity,
                    "state": currentTask.siteState,
                    "country": currentTask.siteCountry,
                    "pin": currentTask.sitePin,
                    "name": currentTask.site,
                    "timeZone": 1,
                    "locationLevel": 0,
                    "type": 1,
                    "status": 0,
                    "objectType": "SITE",
                    "companyId": currentTask.companyId
                },
            }

            payload.apiObject.changeJobDetails(changedJobSitePayload).then((response) => {
                payload.isOngoingLoading = false;
                dispatch(this.onChangeSelectedTaskJobDetailsTabAsync(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Success!!";
                payload.alertSubText = "Task Details Saved Successfully, PDF is getting regenerated";
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.Tasks = [];
                    payload.loadingDashboard = true;
                    payload.offset = 0;
                    payload.limit = TaskInitialState.limit;
                    payload.LoadingTaskDashboard = true;
                    payload.config = {
                        headers: {
                        }
                    };
                    payload.apiObject.regeneratePDF(currentTask.id, isLLG);
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.cleanTaskDashboard(payload));
                    dispatch(this.loadTasksAsync(payload));
                }, 2000);
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.isOngoingLoading = false;
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.onChangeSelectedTaskJobDetailsTabAsync(payload));
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


    // Job Details Tab End


    // Inspect Tab Start 

    findObservationByIdAsync = (payload) => {
        return { type: CONSTANTS.FIND_OBSERVATION_BY_ID, payload };
    }

    findObservationById = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.findObservationByIdAsync(payload));
            payload.isOngoingLoading = false;
            payload.apiObject.getObservationById(payload.SelectedTask.id).then((response) => {
                if (response.checklistData && response.checklistData.length > 0) {
                    payload.CurrentTaskChecklistData = response.checklistData;
                    dispatch(this.findObservationByIdAsync(payload));
                }
                else {
                    payload.apiObject.updateTaskCheckList(
                        payload.SelectedTask.id, CONSTANTS.TASK_STATUS_ONHOLD, true)
                        .then((response) => {
                            payload.isOngoingUpdate = false;
                            payload.SelectedTask = response;
                            dispatch(this.submitOrHoldTaskAsync(payload))

                            payload.hideAlert = false;
                            payload.alertHeader = "Success !!";
                            payload.alertSubText = `Task in ${CONSTANTS.TASK_STATUS_UPDATED[4]} and Checklist updated successfully`;
                            dispatch(this.openCloseAlertModal(payload));
                            setTimeout(() => {
                                payload.hideAlert = true;
                                payload.alertHeader = "";
                                payload.alertSubText = "";
                                dispatch(this.openCloseAlertModal(payload));
                                payload.Tasks = [];
                                payload.loadingDashboard = true;
                                payload.offset = 0;
                                payload.limit = TaskInitialState.limit;
                                payload.LoadingTaskDashboard = true;
                                payload.config = {
                                    headers: {
                                    }
                                };
                                dispatch(this.openCloseAlertModal(payload));
                                dispatch(this.cleanTaskDashboard(payload));
                                dispatch(this.loadTasksAsync(payload));
                            }, 2500);

                        }).catch((error) => {
                            console.error("Error: ", error);

                            payload.hideAlert = false;
                            payload.alertHeader = "Error !!";
                            payload.alertSubText = "Error while updating task";
                            dispatch(this.openCloseAlertModal(payload));

                            setTimeout(() => {
                                payload.hideAlert = true;
                                payload.alertHeader = "";
                                payload.alertSubText = "";
                                dispatch(this.openCloseAlertModal(payload));
                            }, 2500);

                        })
                }
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading task by id";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    findLLGObservationByIdAsync = (payload) => {
        return { type: CONSTANTS.FIND_LLG_OBSERVATION_BY_ID, payload };
    }

    findLLGObservationById = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.findLLGObservationByIdAsync(payload));
            payload.isOngoingLoading = false;
            payload.apiObject.getObservationById(payload.SelectedLLGTask.id).then((response) => {
                if (response.checklistData && response.checklistData.length > 0) {
                    payload.CurrentTaskChecklistData = response.checklistData;
                    dispatch(this.findLLGObservationByIdAsync(payload));
                }
                // else {
                //     payload.apiObject.updateTaskCheckList(
                //         payload.SelectedTask.id, CONSTANTS.TASK_STATUS_ONHOLD, true)
                //         .then((response) => {
                //             payload.isOngoingUpdate = false;
                //             payload.SelectedTask = response;
                //             dispatch(this.submitOrHoldTaskAsync(payload))

                //             payload.hideAlert = false;
                //             payload.alertHeader = "Success !!";
                //             payload.alertSubText = `Task in ${CONSTANTS.TASK_STATUS_UPDATED[4]} and Checklist updated successfully`;
                //             dispatch(this.openCloseAlertModal(payload));
                //             setTimeout(() => {
                //                 payload.hideAlert = true;
                //                 payload.alertHeader = "";
                //                 payload.alertSubText = "";
                //                 dispatch(this.openCloseAlertModal(payload));
                //                 payload.Tasks = [];
                //                 payload.loadingDashboard = true;
                //                 payload.offset = 0;
                //                 payload.limit = TaskInitialState.limit;
                //                 payload.LoadingTaskDashboard = true;
                //                 payload.config = {
                //                     headers: {
                //                     }
                //                 };
                //                 dispatch(this.openCloseAlertModal(payload));
                //                 dispatch(this.cleanTaskDashboard(payload));
                //                 dispatch(this.loadTasksAsync(payload));
                //             }, 2500);

                //         }).catch((error) => {
                //             console.error("Error: ", error);

                //             payload.hideAlert = false;
                //             payload.alertHeader = "Error !!";
                //             payload.alertSubText = "Error while updating task";
                //             dispatch(this.openCloseAlertModal(payload));

                //             setTimeout(() => {
                //                 payload.hideAlert = true;
                //                 payload.alertHeader = "";
                //                 payload.alertSubText = "";
                //                 dispatch(this.openCloseAlertModal(payload));
                //             }, 2500);

                //         })
                // }
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading task by id";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


    findObservationsByParentIdAsync = (payload) => {
        return { type: CONSTANTS.FIND_OBSERVATIONS_BY_PARENT_ID, payload };
    }

    findObservationsByParentId = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.findObservationsByParentIdAsync(payload));
            payload.apiObject.getObservationsByParentId(payload.SelectedTask.id).then((response) => {
                payload.childLLGObservations = response.data;
                payload.isOngoingLoading = false;
                dispatch(this.findObservationsByParentIdAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading tasks by parent id";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


    findFormDetailsByIdAsync = (payload) => {
        return { type: CONSTANTS.FIND_FORM_DETAILS_BY_ID, payload };
    }

    findFormDetailsById = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.findFormDetailsByIdAsync(payload));
            payload.apiObject.loadFormMetadata(
                payload.isRedirectedFromSection ?
                    payload.SelectedCheckListSection.sectionFormId : payload.SelectedCheckListItem.sectionFormId
            ).then((response) => {
                payload.isOngoingLoading = false;
                payload.CurrentChecklistFormData = response.data;
                dispatch(this.findFormDetailsByIdAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading form by id";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    saveCheckListDetailsAsync = (payload) => {
        return { type: CONSTANTS.SAVE_CHECKLIST_DETAILS, payload };
    }

    saveCheckListDetails = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.saveCheckListDetailsAsync(payload));
            payload.apiObject.saveExistingCheckList(payload.SelectedTask.id, payload.CheckListData, payload.files).then(() => {
                payload.isOngoingLoading = false;
                dispatch(this.saveCheckListDetailsAsync(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Success!!";
                payload.alertSubText = "Checklist data saved sucessfully";
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.config = {
                        headers: {
                        }
                    };
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.changeLLGTaskTab({
                        LLGTabIndex: 0
                    }))
                }, 2000);
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while saving checklist";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    errorFormSaveDuetoIncorrectData = (payload) => {
        return dispatch => {
            payload.hideAlert = false;
            payload.alertHeader = "Oops!!";
            payload.alertSubText = "Unable to save form due to incorrect data";
            dispatch(this.openCloseAlertModal(payload));
            setTimeout(() => {
                payload.hideAlert = true;
                payload.alertHeader = "";
                payload.alertSubText = "";
                payload.config = {
                    headers: {
                    }
                };
                dispatch(this.openCloseAlertModal(payload));
            }, 2000);

        }
    }

    populateFormDetails = (payload) => {
        return { type: CONSTANTS.POPULATE_FORM_DETAILS, payload };
    }

    loadItemList = (payload) => {
        return { type: CONSTANTS.LOAD_ITEM_LIST, payload };
    }

    submitOrHoldTaskAsync = (payload) => {
        return { type: CONSTANTS.SUBMIT_OR_HOLD_TASK, payload };
    }

    submitOrHoldTask = (payload) => {
        return dispatch => {

            payload.isOngoingUpdate = true;
            dispatch(this.submitOrHoldTaskAsync(payload))

            let isLLG = payload.SelectedTask.assetType === "LLG" || payload.SelectedTask.taskType === "LLG";

            if (isLLG) {
                payload.apiObject.getLLGChildTasks(payload.SelectedTask.id).then((response) => {
                    const lengthOfChildLlgTasks = response.data.length;
                    if (!lengthOfChildLlgTasks) {
                        payload.hideAlert = false;
                        payload.alertHeader = "Oops!!";
                        payload.alertSubText = "Unable to submit as no child LLG tasks found";
                        dispatch(this.openCloseAlertModal(payload));
                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    } else {

                        payload.apiObject.generateOrReopenTaskAPI(
                            payload.SelectedTask.id,
                            payload.ToBeUpdatedStatus,
                            "",
                            "",
                            payload.ToBeUpdatedStatus === CONSTANTS.TASK_STATUS_CONFIRMED)
                            .then((response) => {
                                payload.isOngoingUpdate = false;
                                payload.SelectedTask = response;
                                dispatch(this.submitOrHoldTaskAsync(payload));
                                if (payload.ToBeUpdatedStatus === CONSTANTS.TASK_STATUS_CONFIRMED) {
                                    payload.apiObject.getParentAndChildLLGTasks(payload.SelectedTask.id).then((response) => {
                                        let childObservationDetails = response[1].data;
                                        payload.apiObject.appTaskUpload(response[0]);
                                        childObservationDetails.forEach((childLLG) => {
                                            childLLG.status = CONSTANTS.TASK_STATUS_CONFIRMED;
                                            //childLLG.actualInspectionDate=
                                            if (childLLG.actualInspectionDate === null || childLLG.actualInspectionDate === undefined) {
                                                childLLG.actualInspectionDate = new Date().toString(); // Setting current timestamp
                                            }
                                            payload.apiObject.appTaskUpload(childLLG);
                                        });
                                    });

                                }

                                payload.hideAlert = false;
                                payload.alertHeader = "Success !!";
                                payload.alertSubText = `Task ${CONSTANTS.TASK_STATUS_UPDATED[payload.ToBeUpdatedStatus]} successfully`;
                                dispatch(this.openCloseAlertModal(payload));
                                setTimeout(() => {
                                    payload.hideAlert = true;
                                    payload.alertHeader = "";
                                    payload.alertSubText = "";
                                    dispatch(this.openCloseAlertModal(payload));
                                    payload.Tasks = [];
                                    payload.loadingDashboard = true;
                                    payload.offset = 0;
                                    payload.limit = TaskInitialState.limit;
                                    payload.LoadingTaskDashboard = true;
                                    payload.config = JSON.parse(localStorage.getItem("config"));
                                    dispatch(this.openCloseAlertModal(payload));
                                    dispatch(this.cleanTaskDashboardWithFilter(payload));
                                    dispatch(this.loadTasksAsync(payload));
                                }, 2500);

                            }).catch((error) => {
                                console.error("Error: ", error);

                                payload.hideAlert = false;
                                payload.alertHeader = "Error !!";
                                payload.alertSubText = "Error while updating task";
                                dispatch(this.openCloseAlertModal(payload));

                                setTimeout(() => {
                                    payload.hideAlert = true;
                                    payload.alertHeader = "";
                                    payload.alertSubText = "";
                                    dispatch(this.openCloseAlertModal(payload));
                                }, 2500);

                            });

                    }
                });
            } else {

                payload.apiObject.generateOrReopenTaskAPI(
                    payload.SelectedTask.id,
                    payload.ToBeUpdatedStatus,
                    "",
                    "",
                    payload.ToBeUpdatedStatus === CONSTANTS.TASK_STATUS_CONFIRMED)
                    .then((response) => {
                        payload.isOngoingUpdate = false;
                        payload.SelectedTask = response;
                        dispatch(this.submitOrHoldTaskAsync(payload));
                        if (payload.ToBeUpdatedStatus === CONSTANTS.TASK_STATUS_CONFIRMED) {
                            payload.apiObject.getObservationById(payload.SelectedTask.id).then((response) => {
                                let observationDetails = []
                                observationDetails.push(response);
                                payload.apiObject.appTaskUpload(observationDetails);
                            });

                        }

                        payload.hideAlert = false;
                        payload.alertHeader = "Success !!";
                        payload.alertSubText = `Task ${CONSTANTS.TASK_STATUS_UPDATED[payload.ToBeUpdatedStatus]} successfully`;
                        dispatch(this.openCloseAlertModal(payload));
                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                            payload.Tasks = [];
                            payload.loadingDashboard = true;
                            payload.offset = 0;
                            payload.limit = TaskInitialState.limit;
                            payload.LoadingTaskDashboard = true;
                            payload.config = JSON.parse(localStorage.getItem("config"));
                            dispatch(this.openCloseAlertModal(payload));
                            dispatch(this.cleanTaskDashboardWithFilter(payload));
                            dispatch(this.loadTasksAsync(payload));
                        }, 2500);

                    }).catch((error) => {
                        console.error("Error: ", error);

                        payload.hideAlert = false;
                        payload.alertHeader = "Error !!";
                        payload.alertSubText = "Error while updating task";
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2500);

                    });
            }
        }
    }

    openCloseCreateChildTaskPanel = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_CREATE_CHILD_TASK_PANEL, payload };
    }

    loadTaskTypesDropDowns = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadTaskTypesDropDownsAsync(payload));
            payload.apiObject.getAllAssetTypesAPI(payload.objectType)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.TaskTypes = response.data;
                    dispatch(this.loadTaskTypesDropDownsAsync(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Task Types";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    loadTaskTypesDropDownsAsync = (payload) => {
        return { type: CONSTANTS.LOAD_TASKTYPES, payload }
    }

    selectTaskType = (payload) => {
        return { type: CONSTANTS.SELECT_TASKTYPE, payload }
    }

    saveNewChildLLGTaskAsync = (payload) => {
        return { type: CONSTANTS.SAVE_CHILD_LLG_TASK, payload };
    }

    saveNewChildLLGTask = (payload) => {
        payload.isOngoingSave = payload.isOngoingLoading = true;
        return dispatch => {
            dispatch(this.saveNewChildLLGTaskAsync(payload));
            payload.apiObject.createChildLLGObservation(
                payload.parentLLGTaskId,
                payload.taskTypeId,
                payload.taskTypeName)
                .then(() => {
                    payload.isOngoingSave = payload.isOngoingLoading = false;
                    dispatch(this.saveNewChildLLGTaskAsync(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Success!!";
                    payload.alertSubText = "New Child Task Added Successfully";
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                        payload.Tasks = [];
                        payload.loadingDashboard = true;
                        payload.offset = 0;
                        payload.limit = TaskInitialState.limit;
                        payload.LoadingTaskDashboard = true;
                        payload.config = {
                            headers: {
                            }
                        };
                        dispatch(this.openCloseAlertModal(payload));
                        dispatch(this.cleanTaskDashboard(payload));
                        dispatch(this.loadTasksAsync(payload));
                    }, 2000);
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.isOngoingSave = payload.isOngoingLoading = false;
                    dispatch(this.saveNewChildLLGTaskAsync(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = error;
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    searchByChildLLGTaskDescription = (payload) => {
        return { type: CONSTANTS.SEARCH_BY_CHILD_LLG_TASK_DESCRIPTION, payload };
    }

    openCloseChildLLGTaskDeleteConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_CHILD_LLG_TASK_DELETE_CONFIRM_MODAL, payload };
    }

    deleteLLGTaskAsync = (payload) => {
        return { type: CONSTANTS.DELETE_CHILD_LLG_TASK, payload };
    }

    deleteLLGTask = (payload) => {
        payload.isOngoingLoading = true;
        return dispatch => {
            dispatch(this.deleteLLGTaskAsync(payload));
            payload.apiObject.deleteChildLLGTask(payload.SelectedLLGTaskToBeDeleted.id)
                .then(() => {
                    payload.isOngoingLoading = false;
                    dispatch(this.deleteLLGTaskAsync(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Success!!";
                    payload.alertSubText = "Child Task Deleted Successfully";
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                        payload.Tasks = [];
                        payload.loadingDashboard = true;
                        payload.offset = 0;
                        payload.limit = TaskInitialState.limit;
                        payload.LoadingTaskDashboard = true;
                        payload.config = {
                            headers: {
                            }
                        };
                        dispatch(this.openCloseAlertModal(payload));
                        dispatch(this.cleanTaskDashboard(payload));
                        dispatch(this.loadTasksAsync(payload));
                    }, 2000);
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.isOngoingSave = payload.isOngoingLoading = false;
                    dispatch(this.deleteLLGTaskAsync(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = error;
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    // Inspect Tab End

    // Attach Asset Tasb Start

    loadAssetsForBlanklocationTaskAsync = (payload) => {
        return { type: CONSTANTS.LOAD_ASSETS_FOR_BLANK_LOCATION_TASK, payload };
    }

    loadAssetsForBlanklocationTask = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadAssetsForBlanklocationTaskAsync(payload));
            payload.apiObject.getAssetsForBlankTask(payload.SelectedTask.id).then((response) => {
                payload.AssetList = response.data;
                payload.isOngoingLoading = false;
                dispatch(this.loadAssetsForBlanklocationTaskAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Assets";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })

        }
    }

    attachAssetToBlankLocationTaskAsync = (payload) => {
        return { type: CONSTANTS.ATTACH_ASSET_TO_BLANK_LOCATION_TASK, payload };
    }

    attachAssetToBlankLocationTask = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
            dispatch(this.openCloseAttachAssetConfirmModal(payload));
            payload.apiObject.attachAssetsForBlankTask(payload.SelectedTask.id, payload.selectedAsset.id, payload.isShiftAndAttach)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.hideAlert = false;
                    payload.alertHeader = "Success !!";
                    payload.alertSubText = `Location ${payload.selectedAsset.name} attached successfully`;
                    dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                        payload.Tasks = [];
                        payload.loadingDashboard = true;
                        payload.offset = 0;
                        payload.limit = TaskInitialState.limit;
                        payload.LoadingTaskDashboard = true;
                        payload.config = {
                            headers: {
                            }
                        };
                        dispatch(this.openCloseAlertModal(payload));
                        dispatch(this.cleanTaskDashboard(payload));
                        dispatch(this.loadTasksAsync(payload));
                    }, 2000)
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Oops!!";
                    payload.alertSubText = "Error while Attaching Asset";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                });
        }
    }

    changeNewAttachAssetName = (payload) => {
        return { type: CONSTANTS.CHANGE_ASSET_NAME_IN_ATTACH_ASSET, payload };
    }

    saveNewAsset = (payload) => {
        payload.isOngoingSave = payload.isOngoingLoading = true;
        return dispatch => {
            dispatch(this.saveNewAssetAsync(payload));
            dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
            payload.locationPayload.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            payload.jobApi.saveAsset(payload.locationPayload).then((response) => {
                payload.apiObject.attachAssetsForBlankTask(payload.SelectedTask.id, response.data, false).then(() => {
                    payload.isOngoingSave = payload.isOngoingLoading = false;
                    dispatch(this.saveNewAssetAsync(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Success!!";
                    payload.alertSubText = "New Asset added and attached successfully to observation";
                    dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                        payload.Tasks = [];
                        payload.loadingDashboard = true;
                        payload.offset = 0;
                        payload.limit = TaskInitialState.limit;
                        payload.LoadingTaskDashboard = true;
                        payload.config = {
                            headers: {
                            }
                        };
                        dispatch(this.openCloseAlertModal(payload));
                        dispatch(this.cleanTaskDashboard(payload));
                        dispatch(this.loadTasksAsync(payload));
                    }, 2000);
                });
            }).catch((error) => {
                console.error("Error: ", error);
                payload.isOngoingSave = payload.isOngoingLoading = false;
                dispatch(this.saveNewAssetAsync(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));
                dispatch(this.attachAssetToBlankLocationTaskAsync(payload));
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.isAsset = true;
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    saveNewAssetAsync = (payload) => {
        return { type: JOB_CONSTANTS.SAVE_NEW_ASSET, payload };
    }

    changeAttachAssetSearchFieldValue = (payload) => {
        return { type: CONSTANTS.CHANGE_ATTACH_ASSET_SEARCH_FIELD_VALUE, payload };
    }

    openCloseAttachAssetConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ATTACH_ASSSET_CONFIRM_MODAL, payload };
    }

    // Attach Asset Tasb End
}