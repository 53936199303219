import React, { PureComponent } from 'react';
import { PrimaryButton, DefaultButton, Spinner, SpinnerSize, ActionButton } from 'office-ui-fabric-react';
import ConfirmTaskRedux from './ConfirmTaskRedux';
import AlertRedux from '../../Alert/AlertRedux';
import { TaskAPI } from '../Services/TaskAPI';
import { IconConstants } from '../../../Constants/StylesConstants';
import LLGCertificatesBrowser from './LLGCertificatesBrowser';
import { TASK_STATUS } from '../Constants/TaskConstants';
import { TaskUtility } from '../Utility/TaskUtility';

export default class Preview extends PureComponent {

    _taskApi = null;
    _taskUtil = null;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
        this._taskUtil = new TaskUtility();
    }

    componentDidMount() {

        if (this.props.Task.SelectedTask.assetType === "LLG" && this.props.Task.SelectedTask.taskType === "LLG") {
            this.props.showTaskPreviewCertificateBrowser({
                isShowTaskCeritificateBrowser: true,
                apiObject: this._taskApi
            })
        }
        else {
            this.props.showTaskPdfAsync({
                SelectedTask: this.props.Task.SelectedTask,
                isShowTaskCeritificateBrowser: false,
                apiObject: this._taskApi,
                TabIndex: this.props.Task.TabIndex
            });
        }

    }

    render() {

        let {
            SelectedTask,
            LoadingTaskPreviewTab,
            LoadingTaskPreviewTabPDF,
            isLoadingChildLLGTasks,
            hideConfirm,
            alertJSON,
            PDFURL,
            isShowTaskCeritificateBrowser,
            ChildLLGTasks,
            SelectedChildLLGTask,
        } = this.props.Task;

        let isUserScheduler = window.loggedInUserDetails.role === "SCHEDULER";
        let isUserFieldEngineer = window.loggedInUserDetails.role === "FIELD-ENGG";
        let isDisabledReopenButton = false;
        let isDisabledAmendButton = false;
        let isDisabledApproveButton = false;
        let isDisabledCancelButton = false;

        let selectedTaskStaus = SelectedTask.status;

        if (isShowTaskCeritificateBrowser && selectedTaskStaus === 8) {
            isDisabledAmendButton = true;
            isDisabledCancelButton = true;
            if (ChildLLGTasks[0]) {
                isDisabledReopenButton =
                    ChildLLGTasks[0].links.length >
                    ChildLLGTasks[0].links.filter((childTask) => parseInt(childTask.url) % 10 === 3).length;
                isDisabledApproveButton = isDisabledReopenButton;
            }
        }
        else if (isShowTaskCeritificateBrowser && selectedTaskStaus === 16) {
            isDisabledReopenButton = true;
            isDisabledApproveButton = true;
            isDisabledCancelButton = true;
            if (ChildLLGTasks[0]) {
                isDisabledAmendButton = this._taskUtil.shouldAmenButtonDisabled(ChildLLGTasks);
            }
        }
        else {
            isDisabledReopenButton = (SelectedTask.status !== 8) || (SelectedTask.observationType === 256);
            isDisabledAmendButton = (SelectedTask.status !== 16) || (SelectedTask.observationType === 256);
            isDisabledCancelButton = (SelectedTask.status !== 1) || (SelectedTask.observationType === 256);
            isDisabledApproveButton = (SelectedTask.status !== 8 )|| (SelectedTask.observationType === 256)|| ((SelectedTask.certificateStatus % 10) !== 3);
            //console.log("selected::"+(SelectedTask.certificateStatus % 10) ===3);
            console.log("((SelectedTask.certificateStatus % 10) !== 3)"+((SelectedTask.certificateStatus % 10) !== 3));
            console.log(isDisabledApproveButton);
            console.log("certificateStatus value:", SelectedTask.certificateStatus);
console.log("certificateStatus type:", typeof SelectedTask.certificateStatus);
console.log("selected::", SelectedTask.certificateStatus % 10 !== 3);
console.log("hiiiiiii");
console.log({
    LoadingTaskPreviewTabPDF,
    LoadingTaskPreviewTab,
    isLoadingChildLLGTasks,
    isDisabledApproveButton,
    isUserFieldEngineer,
    selectedTaskStatus: SelectedTask.status,
    certificateStatus: SelectedTask.certificateStatus,
    certificateStatusType: typeof SelectedTask.certificateStatus,
});
console.log("Final disabled value for Approve button:", 
    LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab || 
    isLoadingChildLLGTasks || isDisabledApproveButton || isUserFieldEngineer);
        }

        return (
            <React.Fragment>

                <div className="ms-Grid task-preview-section" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 task-preview-button-row">
                            {LoadingTaskPreviewTab ?
                                <Spinner
                                    size={SpinnerSize.medium}
                                    label="Please wait..."
                                />
                                :
                                <React.Fragment>
                                    {/* Reopen Task */}
                                    <DefaultButton
                                        disabled={LoadingTaskPreviewTabPDF
                                            || LoadingTaskPreviewTab || isLoadingChildLLGTasks
                                            || isDisabledReopenButton || isUserFieldEngineer||isUserScheduler}
                                        className={LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab ||
                                            isLoadingChildLLGTasks || isDisabledReopenButton || isUserFieldEngineer||isUserScheduler ? "disable-button" : "cancel-button"}
                                        text="Reopen"
                                        menuIconProps={IconConstants.reopenIcon}
                                        onClick={() => {
                                            this.props.openCloseTaskConfirmModal({
                                                isLLGDelete: false,
                                                SelectedTask: SelectedTask,
                                                ShowPDF: false,
                                                hideConfirm: false,
                                                ToBeUpdatedStatus: 2
                                            })
                                        }}
                                    />

                                    {/* Amend Task */}
                                    <DefaultButton
                                        disabled={LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab
                                            || isLoadingChildLLGTasks || isDisabledAmendButton || isUserFieldEngineer}
                                        className={LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab
                                            || isLoadingChildLLGTasks || isDisabledAmendButton || isUserFieldEngineer ? "disable-button" : "info-button"}
                                        text="Amend"
                                        menuIconProps={IconConstants.amendIcon}
                                        onClick={() => {
                                            this.props.openCloseTaskConfirmModal({
                                                isLLGDelete: false,
                                                SelectedTask: SelectedTask,
                                                ShowPDF: false,
                                                hideConfirm: false,
                                                ToBeUpdatedStatus: 32
                                            })
                                        }}
                                    />

                                    {/* Cancel Task */}
                                    {window.loggedInUserDetails.role !== "APPROVER" &&
                                        <DefaultButton
                                            disabled={LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab
                                                || isLoadingChildLLGTasks || isDisabledCancelButton || isUserFieldEngineer}
                                            className={(LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab
                                                || isLoadingChildLLGTasks || isDisabledCancelButton || isUserFieldEngineer) ? "disable-button" : "other-button"}
                                            text="Cancel"
                                            menuIconProps={IconConstants.cancelIcon}
                                            onClick={() => {
                                                this.props.openCloseTaskConfirmModal({
                                                    isLLGDelete: false,
                                                    SelectedTask: SelectedTask,
                                                    ShowPDF: false,
                                                    hideConfirm: false,
                                                    ToBeUpdatedStatus: 64
                                                })
                                            }}
                                        />
                                    }

                                    {/* Approve */}
                                    {window.loggedInUserDetails.role !== "SCHEDULER" &&
                                        <PrimaryButton
                                            disabled={LoadingTaskPreviewTabPDF || LoadingTaskPreviewTab
                                                || isLoadingChildLLGTasks || isDisabledApproveButton || isUserFieldEngineer||((SelectedTask.certificateStatus % 10) !== 3)}
                                            text="Approve"
                                            menuIconProps={IconConstants.approveIcon}
                                            onClick={() => {
                                                this.props.openCloseTaskConfirmModal({
                                                    isLLGDelete: false,
                                                    SelectedTask: SelectedTask,
                                                    ShowPDF: true,
                                                    hideConfirm: false,
                                                    ToBeUpdatedStatus: 8
                                                })
                                            }}
                                        />
                                    }
                                </React.Fragment>
                            }

                        </div>

                        {isShowTaskCeritificateBrowser &&
                            <div className={["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg4"].join(" ")}>
                                <LLGCertificatesBrowser
                                    {...this.props}
                                />
                            </div>
                        }
                        {PDFURL &&
                            <div className={
                                isShowTaskCeritificateBrowser ?
                                    ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg8"].join(" ")
                                    :
                                    ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg12"].join(" ")
                            }>
                                <div className="ms-Grid" dir="ltr">
                                    {isShowTaskCeritificateBrowser
                                        && !LoadingTaskPreviewTab
                                        && (window.loggedInUserDetails.role === "APPROVER"||window.loggedInUserDetails.role === "APPROVER-ONLY")
                                        && TASK_STATUS[SelectedChildLLGTask?.status] !== "CANCELLED"
                                        &&
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                                <div id="remove">
                                                    <ActionButton
                                                        disbled={!SelectedChildLLGTask}
                                                        text={`${TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ? "Reopen Record" : " "} `}
                                                        iconProps={TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ?
                                                            IconConstants.reopenIcon : IconConstants.verticalIcon}
                                                        onClick={(event) => {
                                                            this.props.openCloseTaskConfirmModal({
                                                                isLLGDelete: true,
                                                                SelectedTask: SelectedChildLLGTask,
                                                                ShowPDF: false,
                                                                hideConfirm: false,
                                                                ToBeUpdatedStatus: TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ? 2 : 64
                                                            })
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {LoadingTaskPreviewTabPDF &&
                                        <div className="login-spinner-div">
                                            <Spinner
                                                label="Loading Task PDF"
                                                size={SpinnerSize.large}
                                            />
                                        </div>
                                    }
                                    <div className="ms-Grid-row iframe-row">
                                        <iframe
                                            id="iframeTaskPdf"
                                            title={SelectedTask.id}
                                            src={PDFURL}
                                            type="application/pdf"
                                            className="iframe-class"
                                            frameBorder={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    !hideConfirm &&
                    <ConfirmTaskRedux
                        {...this.props}
                    />
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
