import React, { PureComponent } from 'react';
import { Spinner, SpinnerSize, ActionButton } from 'office-ui-fabric-react';
import ConfirmTaskRedux from './ConfirmTaskRedux';
import AlertRedux from '../../Alert/AlertRedux';
import { TaskAPI } from '../Services/TaskAPI';
import { IconConstants } from '../../../Constants/StylesConstants';
import LLGCertificatesBrowser from './LLGCertificatesBrowser';
import { TASK_STATUS } from '../Constants/TaskConstants';

export default class FullReport extends PureComponent {

    _taskApi = null;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
    }

    componentDidMount() {
        console.log("this.props.Task.SelectedTask.assetType::"+this.props.Task.SelectedTask.assetType);
        console.log("this.props.Task.SelectedTask.taskType::"+this.props.Task.SelectedTask.taskType);
        if (this.props.Task.SelectedTask.assetType === "LLG" && this.props.Task.SelectedTask.taskType === "LLG") {
           // console.log("this.props.Task.SelectedTask.assetType::"+this.props.Task.SelectedTask.assetType);
            //console.log("this.props.Task.SelectedTask.taskType::"+this.props.Task.SelectedTask.taskType);
            this.props.showTaskPreviewCertificateBrowser({
                isShowTaskCeritificateBrowser: true,
                apiObject: this._taskApi
                
            })
            
        }
        else {
            console.log(this.props.Task.TabIndex);
            this.props.showTaskfFullReportAsync({
                SelectedTask: this.props.Task.SelectedTask,
                isShowTaskCeritificateBrowser: false,
                apiObject: this._taskApi,
                TabIndex: this.props.Task.TabIndex    
            })
        }

    }

    render() {

        let {
            SelectedTask,
            LoadingTaskPreviewTab,
            LoadingTaskPreviewTabPDF,
            hideConfirm,
            alertJSON,
            PDFURL,
            isShowTaskCeritificateBrowser,
            SelectedChildLLGTask
        } = this.props.Task;

        return (
            <React.Fragment>

                <div className="ms-Grid task-preview-section" dir="ltr">
                    <div className="ms-Grid-row">
                        {isShowTaskCeritificateBrowser &&
                            <div className={["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg4"].join(" ")}>
                                <LLGCertificatesBrowser
                                    {...this.props}
                                />
                            </div>
                        }
                        {PDFURL &&
                            <div className={
                                isShowTaskCeritificateBrowser ?
                                    ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg8"].join(" ")
                                    :
                                    ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg12"].join(" ")
                            }>
                                <div className="ms-Grid" dir="ltr">
                                    {isShowTaskCeritificateBrowser
                                        && !LoadingTaskPreviewTab
                                        && window.loggedInUserDetails.role === "APPROVER"
                                        && TASK_STATUS[SelectedChildLLGTask?.status] !== "CANCELLED"
                                        &&
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                                <div id="remove">
                                                    <ActionButton
                                                        disbled={!SelectedChildLLGTask}
                                                        text={`${TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ? "Reopen" : "Remove"} Record`}
                                                        iconProps={TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ?
                                                            IconConstants.reopenIcon : IconConstants.cancelIcon}
                                                        onClick={(event) => {
                                                            this.props.openCloseTaskConfirmModal({
                                                                isLLGDelete: true,
                                                                SelectedTask: SelectedChildLLGTask,
                                                                ShowPDF: false,
                                                                hideConfirm: false,
                                                                ToBeUpdatedStatus: TASK_STATUS[SelectedChildLLGTask?.status] === "CANCELLED" ? 2 : 64
                                                            })
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {LoadingTaskPreviewTabPDF &&
                                        <div className="login-spinner-div">
                                            <Spinner
                                                label="Loading Task PDF"
                                                size={SpinnerSize.large}
                                            />
                                        </div>
                                    }
                                    <div className="ms-Grid-row iframe-row">
                                        <iframe
                                            id="iframeTaskPdf"
                                            title={SelectedTask.id}
                                            src={PDFURL}
                                            type="application/pdf"
                                            className="iframe-class"
                                            frameBorder={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                {
                    !hideConfirm &&
                    <ConfirmTaskRedux
                        {...this.props}
                    />
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}